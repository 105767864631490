import "./Preloader.css";

const Preloader = (props) => {
  return (
    <div className="loader-box">
      <div className="loader-content">
        <svg
          className="loader__img"
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="130"
          height="130"
          viewBox="0 0 1200.000000 900.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
            fill="#fff"
            stroke="none"
          >
            <path
              d="M5675 7699 c-153 -12 -354 -47 -520 -89 -434 -110 -869 -333 -1215
          -623 -607 -507 -987 -1202 -1096 -2002 -25 -182 -25 -588 0 -770 79 -582 301
          -1108 656 -1555 107 -135 355 -383 490 -490 234 -186 592 -389 853 -485 213
          -78 468 -140 704 -172 181 -24 589 -24 768 1 373 51 679 144 1005 307 314 157
          553 330 806 583 258 258 429 496 589 821 144 292 227 546 279 855 75 442 55
          884 -59 1318 -105 400 -315 816 -580 1147 -102 128 -352 378 -480 480 -249
          199 -604 398 -890 500 -410 147 -878 209 -1310 174z m1415 -1389 c5 0 177
          -201 382 -447 205 -247 383 -458 395 -471 21 -21 23 -33 23 -122 0 -55 -4
          -100 -9 -100 -5 0 -11 -10 -15 -23 -23 -94 -136 -216 -252 -273 l-79 -39 -5
          -850 c-3 -467 -9 -858 -14 -867 -8 -17 -92 -18 -1571 -18 l-1563 0 -8 23 c-5
          12 -11 402 -14 867 l-5 845 -80 40 c-104 51 -186 132 -240 237 -22 43 -43 78
          -47 78 -5 0 -8 39 -8 88 0 82 2 88 24 102 14 8 65 65 115 125 49 61 219 265
          378 455 l288 345 1135 5 c624 3 1141 4 1148 3 8 -2 17 -3 22 -3z"
            />
            <path
              d="M5282 6178 c-5 -7 -106 -204 -223 -438 -240 -478 -234 -460 -180
          -576 37 -78 105 -147 179 -182 49 -23 70 -27 152 -27 81 0 103 4 150 26 114
          54 195 170 210 301 10 79 44 292 60 373 6 28 26 149 45 269 20 120 38 223 41
          228 3 5 0 15 -6 23 -17 20 -412 22 -428 3z"
            />
            <path
              d="M6175 6180 c-3 -6 -2 -34 4 -63 11 -51 36 -193 51 -292 15 -93 40
          -240 55 -320 8 -44 19 -118 25 -165 6 -47 18 -110 27 -140 25 -91 97 -172 193
          -219 48 -22 68 -26 150 -26 82 0 103 4 150 26 113 54 201 177 207 289 3 54 -1
          63 -215 485 l-217 430 -212 3 c-138 1 -214 -1 -218 -8z"
            />
            <path
              d="M4793 4957 c-17 -17 -69 -52 -115 -77 l-83 -45 -3 -377 c-2 -364 -2
-378 17 -387 13 -7 451 -10 1351 -8 1170 2 1332 4 1340 17 14 21 13 726 0 751
-6 10 -15 19 -21 19 -25 0 -119 52 -170 94 -31 25 -60 46 -64 46 -4 0 -27 -18
-50 -39 -172 -159 -458 -159 -630 0 -48 45 -57 47 -85 19 -31 -31 -102 -80
-140 -97 -174 -76 -355 -50 -505 72 -30 25 -58 45 -61 45 -4 0 -26 -18 -49
-39 -108 -100 -290 -145 -430 -107 -70 18 -145 58 -200 106 -25 22 -52 40 -58
40 -7 0 -26 -15 -44 -33z"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Preloader;
