
const ProductImgList  = (props) => {
    return(
          
                     <div className="prod-img">
                        <img src={props.imgSrc} width="130px" alt="Product-images"/>
                     </div> 
              
    )
}


export default ProductImgList;

