import React from "react";

const SupportImg = () => {
  return (
    <div className="support__img">
      <svg data-name="Layer 1" width="100%" height="336" viewBox="0 0 523 336">
        <circle
          cx="766.34968"
          cy="561.77555"
          r="43.06732"
          transform="translate(-511.27668 424.4313) rotate(-45)"
          fill="#2f2e41"
        />
        <circle
          cx="762.92874"
          cy="551.36577"
          r="14.71924"
          transform="translate(-439.63078 -104.08519) rotate(-12.48125)"
          fill="#fff"
        />
        <circle
          cx="756.9663"
          cy="547.56454"
          r="4.90643"
          transform="translate(-438.95016 -105.46366) rotate(-12.48125)"
          fill="#3f3d56"
        />
        <path
          d="M764.64834,532.6599c-17.61323-.03251-29.97775-3.17854-29.94827-19.13582s14.33174-28.86688,31.945-28.83431,31.86768,12.99488,31.8382,28.95216C798.45372,529.59929,782.26156,532.69241,764.64834,532.6599Z"
          transform="translate(-338.5 -282)"
          fill="#81007f"
        />
        <path
          d="M833.85155,568.97444c-5.05211,17.52641-19.80364,29.79509-35.19135,40.21716q-3.22281,2.18327-6.44766,4.266c-.01457.00636-.02957.02-.04432.02649-.10343.06645-.20707.133-.30344.19991-.44372.286-.88759.57222-1.32762.855l.24171.10858s.23735.12621-.02233.02024c-.07728-.03233-.15825-.06136-.23537-.09387-8.93929-3.60536-18.03735-7.4764-24.888-14.23042-7.1065-7.0136-11.25952-18.01413-7.41141-27.228a17.5918,17.5918,0,0,1,1.90481-3.41886c.31214-.44666.64831-.87416.99832-1.29373a18.85591,18.85591,0,0,1,32.3856,5.72979c1.1305-10.52576-7.54879-19.34265-15.90135-25.84822-8.35623-6.50228-17.85125-13.58341-19.46674-24.05074-.90215-5.82388,1.12306-11.46778,4.81224-15.99852.11421-.13766.22827-.27514.3459-.409a27.51849,27.51849,0,0,1,17.68718-9.48885c12.81321-1.33167,25.25927,5.4506,34.56779,14.35941C830.53754,527.0305,839.59482,549.047,833.85155,568.97444Z"
          transform="translate(-338.5 -282)"
          fill="#e6e6e6"
        />
        <path
          d="M809.76735,546.05008a46.77965,46.77965,0,0,1,5.60443,12.36093,40.61967,40.61967,0,0,1,1.404,11.9968,52.04376,52.04376,0,0,1-6.81624,23.68461A74.9024,74.9024,0,0,1,798.6602,609.1916q-3.22281,2.18327-6.44766,4.266c-.01457.00636-.02957.02-.04432.02649-.10343.06645-.20707.133-.30344.19991-.44372.286-.88759.57222-1.32762.855,0,0,.47906.23479.21938.12882-.07728-.03233-.15825-.06136-.23537-.09387a41.40487,41.40487,0,0,0-13.249-35.6508,41.75957,41.75957,0,0,0-17.14558-9.22645c.31214-.44666.64831-.87416.99832-1.29373a43.296,43.296,0,0,1,7.14664,2.70436,42.424,42.424,0,0,1,19.18179,18.49347,43.37384,43.37384,0,0,1,4.72253,23.80133c.36729-.33284.735-.67291,1.09167-1.00976,6.7929-6.33309,12.82634-13.60244,16.90958-21.99189A48.41647,48.41647,0,0,0,815.247,567.493c-.33324-8.65329-3.781-16.66846-8.77294-23.64614a101.34553,101.34553,0,0,0-19.21051-19.79842,115.15792,115.15792,0,0,0-23.89219-14.75057.828.828,0,0,1-.41667-1.06262.7042.7042,0,0,1,.3459-.409.61308.61308,0,0,1,.53243.02261c1.0539.48518,2.10048.977,3.13867,1.49007a116.46492,116.46492,0,0,1,24.06247,15.87266C798.11694,531.25013,804.785,538.14914,809.76735,546.05008Z"
          transform="translate(-338.5 -282)"
          fill="#fff"
        />
        <ellipse
          cx="73.71401"
          cy="331.4603"
          rx="10.90314"
          ry="4.08868"
          fill="#2f2e41"
        />
        <ellipse
          cx="99.88149"
          cy="330.91516"
          rx="10.90314"
          ry="4.08868"
          fill="#2f2e41"
        />
        <ellipse
          cx="469.74364"
          cy="542.42432"
          rx="21.53369"
          ry="6.76007"
          transform="translate(-546.62487 25.10005) rotate(-29.96257)"
          fill="#2f2e41"
        />
        <circle
          cx="420.93647"
          cy="555.94632"
          r="43.06735"
          transform="translate(-533.75772 600.39468) rotate(-80.78252)"
          fill="#2f2e41"
        />
        <rect
          x="62.81087"
          y="307.746"
          width="13.08374"
          height="23.44171"
          fill="#2f2e41"
        />
        <rect
          x="88.97835"
          y="307.746"
          width="13.08374"
          height="23.44171"
          fill="#2f2e41"
        />
        <circle cx="83.52681" cy="263.04321" r="14.71922" fill="#fff" />
        <circle cx="83.52681" cy="263.04321" r="4.90642" fill="#3f3d56" />
        <path
          d="M379.15975,515.89493c-3.47748-15.57379,7.63867-31.31046,24.82861-35.14884s33.94421,5.67508,37.42169,21.24887-7.91491,21.31766-25.10486,25.156S382.63723,531.46875,379.15975,515.89493Z"
          transform="translate(-338.5 -282)"
          fill="#e6e6e6"
        />
        <ellipse
          cx="372.42832"
          cy="535.96292"
          rx="6.76007"
          ry="21.53368"
          transform="translate(-609.92557 360.7865) rotate(-64.62574)"
          fill="#2f2e41"
        />
        <path
          d="M399.78194,571.48328c0,4.21514,10.85327,12.53857,22.89654,12.53857s23.33515-11.867,23.33515-16.08209-11.29193.81775-23.33515.81775S399.78194,567.26813,399.78194,571.48328Z"
          transform="translate(-338.5 -282)"
          fill="#fff"
        />
        <ellipse
          cx="232.60883"
          cy="331.4603"
          rx="10.90314"
          ry="4.08868"
          fill="#2f2e41"
        />
        <ellipse
          cx="206.44135"
          cy="330.91516"
          rx="10.90314"
          ry="4.08868"
          fill="#2f2e41"
        />
        <ellipse
          cx="513.57919"
          cy="542.42432"
          rx="6.76007"
          ry="21.53369"
          transform="translate(-551.35011 434.45934) rotate(-60.03743)"
          fill="#2f2e41"
        />
        <circle cx="223.88636" cy="273.94632" r="43.06734" fill="#2f2e41" />
        <rect
          x="230.42822"
          y="307.746"
          width="13.08374"
          height="23.44171"
          fill="#2f2e41"
        />
        <rect
          x="204.26074"
          y="307.746"
          width="13.08374"
          height="23.44171"
          fill="#2f2e41"
        />
        <circle cx="213.79602" cy="263.04321" r="14.71922" fill="#fff" />
        <ellipse
          cx="545.29602"
          cy="543.04321"
          rx="4.92055"
          ry="4.88593"
          transform="translate(-562.77667 262.29055) rotate(-44.97427)"
          fill="#3f3d56"
        />
        <path
          d="M567.01764,527.15094c-17.18994-3.83832-28.58234-9.58219-25.10486-25.156s20.23175-25.08725,37.42169-21.24887,28.3061,19.57505,24.82862,35.14884S584.20758,530.98926,567.01764,527.15094Z"
          transform="translate(-338.5 -282)"
          fill="#81007f"
        />
        <ellipse
          cx="601.89451"
          cy="567.96293"
          rx="6.76007"
          ry="21.53368"
          transform="translate(-464.12902 -108.45868) rotate(-14.87459)"
          fill="#2f2e41"
        />
        <path
          d="M552.62933,568.75815c-7.90666,0-15.32008-5.03284-15.32008-.81775s7.41338,16.08209,15.32008,16.08209,15.03213-8.32343,15.03213-12.53857S560.536,568.75815,552.62933,568.75815Z"
          transform="translate(-338.5 -282)"
          fill="#fff"
        />
        <path
          d="M496.97168,484.53906H493.147a12.93742,12.93742,0,0,1-12.92285-12.92285v-3.21094c0-7.95312,1.50684-14.6123,4.479-19.79248a104.11414,104.11414,0,0,1,12.542-16.85156,74.26915,74.26915,0,0,0,8.52783-10.27881,17.29,17.29,0,0,0,2.69336-9.5625c0-7.978-5.19776-11.85644-15.89063-11.85644a64.52587,64.52587,0,0,0-17.51513,2.6289,14.7451,14.7451,0,0,1-18.79248-14.17822v-6.5a14.46343,14.46343,0,0,1,8.53222-13.25635A78.08334,78.08334,0,0,1,496.61035,362.5c11.44434,0,21.77539,1.92236,30.707,5.71289,8.94776,3.79932,16.01709,9.15674,21.01123,15.92383a38.35194,38.35194,0,0,1,7.53614,23.24414c0,8.98-2.45166,16.80127-7.28663,23.24658-4.78222,6.37988-11.63769,13.36426-20.376,20.76026a145.20407,145.20407,0,0,0-13.06348,12.30664,22.71817,22.71817,0,0,0-5.43115,10.11132A12.888,12.888,0,0,1,496.97168,484.53906Z"
          transform="translate(-338.5 -282)"
          fill="#81007f"
        />
        <path
          d="M494.84521,545.31055h-.50439a23.95118,23.95118,0,0,1,0-47.90235h.50439a23.95118,23.95118,0,1,1,0,47.90235Z"
          transform="translate(-338.5 -282)"
          fill="#81007f"
        />
        <path
          d="M650.5,618h-311a1,1,0,0,1,0-2h311a1,1,0,0,1,0,2Z"
          transform="translate(-338.5 -282)"
          fill="#ccc"
        />
        <path
          d="M860.5,618h-166a1,1,0,0,1,0-2h166a1,1,0,0,1,0,2Z"
          transform="translate(-338.5 -282)"
          fill="#ccc"
        />
        <ellipse
          cx="757.33858"
          cy="582.0315"
          rx="21.53368"
          ry="6.76007"
          transform="translate(-349.73597 -267.08009) rotate(-1.12041)"
          fill="#2f2e41"
        />
        <path
          d="M746.66142,431h-26a5.00573,5.00573,0,0,1-5-5V400a5.00573,5.00573,0,0,1,5-5h26a5.00573,5.00573,0,0,1,5,5v26A5.00573,5.00573,0,0,1,746.66142,431Zm-26-34a3.00328,3.00328,0,0,0-3,3v26a3.00328,3.00328,0,0,0,3,3h26a3.00328,3.00328,0,0,0,3-3V400a3.00328,3.00328,0,0,0-3-3Z"
          transform="translate(-338.5 -282)"
          fill="#f2f2f2"
        />
        <path
          d="M758.66142,414h-26a5.00573,5.00573,0,0,1-5-5V383a5.00573,5.00573,0,0,1,5-5h26a5.00573,5.00573,0,0,1,5,5v26A5.00573,5.00573,0,0,1,758.66142,414Zm-26-34a3.00328,3.00328,0,0,0-3,3v26a3.00328,3.00328,0,0,0,3,3h26a3.00328,3.00328,0,0,0,3-3V383a3.00328,3.00328,0,0,0-3-3Z"
          transform="translate(-338.5 -282)"
          fill="#f2f2f2"
        />
        <path
          d="M553.66142,331h-26a5.00573,5.00573,0,0,1-5-5V300a5.00573,5.00573,0,0,1,5-5h26a5.00573,5.00573,0,0,1,5,5v26A5.00573,5.00573,0,0,1,553.66142,331Zm-26-34a3.00328,3.00328,0,0,0-3,3v26a3.00328,3.00328,0,0,0,3,3h26a3.00328,3.00328,0,0,0,3-3V300a3.00328,3.00328,0,0,0-3-3Z"
          transform="translate(-338.5 -282)"
          fill="#f2f2f2"
        />
        <path
          d="M537.66142,318h-26a5.00573,5.00573,0,0,1-5-5V287a5.00573,5.00573,0,0,1,5-5h26a5.00573,5.00573,0,0,1,5,5v26A5.00573,5.00573,0,0,1,537.66142,318Zm-26-34a3.00328,3.00328,0,0,0-3,3v26a3.00328,3.00328,0,0,0,3,3h26a3.00328,3.00328,0,0,0,3-3V287a3.00328,3.00328,0,0,0-3-3Z"
          transform="translate(-338.5 -282)"
          fill="#f2f2f2"
        />
        <circle cx="321.22047" cy="183.17011" r="6.46689" fill="#f2f2f2" />
        <circle cx="61.22047" cy="160.17011" r="6.46689" fill="#f2f2f2" />
        <circle cx="497.22047" cy="216.17011" r="6.46689" fill="#f2f2f2" />
      </svg>
    </div>
  );
};

export default SupportImg;
