import React from "react";

const AboutSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 776.31999 764.05063"
      className="about__svg"
    >
      <circle cx="113.44" cy="373.97061" r="113.44" fill="#f2f2f2" />
      <circle cx="114.45354" cy="333.46381" r="45.23622" fill="#2f2e41" />
      <path
        d="M439.33463,181.61582a113.44,113.44,0,1,1-113.2387-113.641A113.14677,113.14677,0,0,1,439.33463,181.61582Z"
        transform="translate(-211.84 -67.97468)"
        fill="#f2f2f2"
      />
      <circle cx="382.62999" cy="373.97061" r="113.44" fill="#f2f2f2" />
      <circle cx="382.3" cy="113.44001" r="113.44" fill="#f2f2f2" />
      <circle cx="658.78001" cy="113.44001" r="113.44" fill="#f2f2f2" />
      <circle cx="662.87999" cy="373.97061" r="113.44" fill="#f2f2f2" />
      <path
        d="M439.33481,718.58467a113.44,113.44,0,1,1-113.44-113.44A112.93848,112.93848,0,0,1,439.33481,718.58467Z"
        transform="translate(-211.84 -67.97468)"
        fill="#f2f2f2"
      />
      <circle cx="389.59001" cy="650.61063" r="113.44" fill="#f2f2f2" />
      <path
        d="M273.31639,144.51271c4.6907-14.94907,15.3575-28.65983,30.10944-33.71343,14.75282-5.05361,36.35465,2.64468,47.44545,13.698,20.28723,20.21866,25.23809,89.67712,11.29991,111.62678-2.77235-.14675-12.33866-.25529-15.1446-.36038L343.05726,222.534v13.09923q-6.70539-.20722-13.5157-.28613A58.82126,58.82126,0,0,1,271.4675,177.9772C271.1043,163.41792,271.46789,150.40381,273.31639,144.51271Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <circle cx="112.37232" cy="93.75326" r="32.32961" fill="#9e616a" />
      <path
        d="M296.31087,133.33471l37.22443-18.63919,0,0a46.78735,46.78735,0,0,1,27.40329,38.764l.92783,11.11382-15.58778-3.968-1.70776-14.21117-2.64705,13.10265-7.19363-1.83034.06839-22.06279-7.19811,22.04705-25.6926-5.90285Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <circle cx="351.47893" cy="315.38844" r="16.01581" fill="#2f2e41" />
      <circle cx="377.10423" cy="351.69095" r="40.5734" fill="#2f2e41" />
      <circle cx="380.46161" cy="359.28383" r="32.97046" fill="#9e616a" />
      <path
        d="M614.69193,393.64969A37.76662,37.76662,0,0,0,557.27331,426.61c16.80015,4.218,34.44978,7.44594,52.36594,1.0089l4.231-10.3551,2.49441,10.36278c5.45607,2.2301,10.92628,4.117,16.39646-.02154A38.56183,38.56183,0,0,0,614.69193,393.64969Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <path
        d="M548.4072,339.88621c-11.07487-5.078-24.97075-2.69524-34.19229,5.26731-9.2216,7.96255-13.60556,20.92176-11.82528,32.97455,1.816,12.29567,9.291,22.88386,16.18121,33.22825,6.89027,10.34433,13.62172,21.67948,13.614,34.10849a33.8112,33.8112,0,0,1-33.74406,33.79033c10.50666,4.52315,21.47283,9.135,32.89921,8.59879,11.42646-.53614,23.40293-7.88181,25.26682-19.16788,1.343-8.13206-2.64669-16.06183-5.97426-23.60248a166.953,166.953,0,0,1-11.668-38.40921c-1.11132-6.31236-1.835-12.94853.223-19.01859a22.33269,22.33269,0,0,1,22.18578-14.65542l3.77547-3.39665C566.82826,357.53638,559.48214,344.96414,548.4072,339.88621Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <path
        d="M824.32325,137.04323a106.58472,106.58472,0,0,1-7.60457,24.27132c-20.011,42.3329,36.97644,28.53455,57.34185,28.53455,22.26658,0,43.57108,11.70876,40.31717-40.31721-1.3899-22.22318-18.05066-40.31721-40.31717-40.31721C855.04123,109.2147,828.30024,117.66842,824.32325,137.04323Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <circle cx="658.8844" cy="89.10216" r="32.76228" fill="#ffb6b6" />
      <path
        d="M848.47543,123.68022a37.52812,37.52812,0,0,1,57.05609,32.75217c-16.69411,4.19139-34.23233,7.39892-52.03526,1.00253l-4.20439-10.28972-2.4787,10.29734c-5.4216,2.216-10.85731,4.091-16.293-.02139A38.31834,38.31834,0,0,1,848.47543,123.68022Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <circle cx="114.47194" cy="344.23107" r="40.41146" fill="#ffb8b8" />
      <circle
        id="bf829f4c-57e2-46a5-971d-584c2690be65"
        data-name="ab6171fa-7d69-4734-b81c-8dff60f9761b"
        cx="387.09271"
        cy="627.96253"
        r="34.41493"
        fill="#9e616a"
      />
      <circle cx="386.92353" cy="88.02086" r="36.99077" fill="#ffb8b8" />
      <path
        id="bd502a69-4a38-4003-a7a1-fa248c8198be-206"
        data-name="bf427902-b9bf-4946-b5d7-5c1c7e04535e"
        d="M631.33265,131.55875s9.6311-18.41175-11.55762-20.08557c0,0-18.06356-16.386-36.47532-2.9956,0,0-10.04277,0-15.53453,11.36458,0,0-7.89861-2.9956-9.63439,5.02139,0,0-5.77965,16.738,0,31.80219s7.69941,16.738,7.69941,16.738-4.05514-18.69381,19.06179-20.36763,44.32452-16.15559,46.25105,2.25615,4.04435,10.09445,4.04435,10.09445S653.48525,139.09088,631.33265,131.55875Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <circle cx="659.74903" cy="351.81211" r="36.21126" fill="#ffb6b6" />
      <path
        d="M372.28931,691.44194v-.00005l-.5613,1.01178c4.996-12.31271.453-27.48487-8.45946-36.769-8.91212-9.28416-21.302-13.52858-33.502-15.21248-9.05663-1.25-18.45729-1.2139-27.06877,2.10351-12.47055,4.804-21.99828,15.925-29.95316,27.49392-3.6387,5.29148-7.21626,11.324-6.85379,17.93775.33651,6.13757,3.97966,11.34594,6.96312,16.55316,2.9838,5.20722,5.47531,11.66767,3.334,17.351-1.276,3.3868-3.97282,5.826-6.161,8.59654s-3.98819,6.51528-2.93119,9.99146a8.15179,8.15179,0,0,0,6.07317,5.278c2.64525.61647,5.38957.21365,8.06863-.19114L350.77717,735.08A29.63674,29.63674,0,0,0,372.28931,691.44194Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <circle
        cx="320.56459"
        cy="700.59598"
        r="33.99924"
        transform="translate(-634.17366 836.82356) rotate(-80.78253)"
        fill="#ffb9b9"
      />
      <path
        d="M411.0748,793.48464a113.40031,113.40031,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18393,29.18393,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M685.0748,793.48464a113.40031,113.40031,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18393,29.18393,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M352.81363,669.04066c-.87765-4.64289-5.613-6.76643-9.6702-7.79542a93.15138,93.15138,0,0,0-36.03623-1.97885c-8.76693,1.25024-17.74191,4.00707-24.10307,10.85006a32.41655,32.41655,0,0,0-8.19675,20.87684,24.86134,24.86134,0,0,0,2.9756,13.26287c2.28927,3.87452,6.191,6.6849,10.21886,6.58621l.40791,1.41867c17.06992-2.37831,32.92637-12.99939,42.91291-28.74391a36.82509,36.82509,0,0,1-10.75863,17.3802,67.03419,67.03419,0,0,0,28.93714-22.30152C351.52055,675.9155,353.48117,672.573,352.81363,669.04066Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <path
        d="M410.0748,516.48464a113.40031,113.40031,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18393,29.18393,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M679.825,517.84526a113.4003,113.4003,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42005,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18394,29.18394,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M959.825,517.84526a113.4003,113.4003,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42005,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18394,29.18394,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M410.0748,256.48464a113.40031,113.40031,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18393,29.18393,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M678.825,256.84526a113.4003,113.4003,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42005,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18394,29.18394,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M958.825,256.84526a113.4003,113.4003,0,0,1-158.71,11.47l9.73-27.15a29.19669,29.19669,0,0,1,24.8-17.03l6.19-.38,14.76-12.73s39.77-1.42005,39.18-2.1l24.09,14.49-.02-.1,9.47.58a29.18394,29.18394,0,0,1,24.8,17.03Z"
        transform="translate(-211.84 -67.97468)"
        fill="#81007f"
      />
      <path
        d="M909.7799,397.32929A46.68665,46.68665,0,0,0,890.2282,370.728c-7.60242-4.86255-17.08893-7.345-25.70166-4.7735s-15.74341,11.02381-15.17963,20.15613c-3.10882-1.05792-6.64319.34955-8.91888,2.75427-2.2757,2.40479-3.49939,5.63959-4.37579,8.85956a56.325,56.325,0,0,0,.52825,31.18982l-.171,1.45532c4.20191-.69512,7.3891-4.30041,9.371-8.1416s3.12243-8.10266,5.2705-11.84991c2.14808-3.74719,5.71515-7.08991,9.97046-7.20685l-.375,6.50458q5.49162-2.82935,10.98316-5.65869-1.30628,3.12469-2.61249,6.24939,4.79591-2.00455,9.5918-4.009-.53613,3.00468-1.07221,6.00928c3.171-7.21735,12.97711-10.38788,19.64777-6.35266,3.87414,2.34357,6.41583,6.52008,7.75281,10.90613s1.60138,9.02582,1.85656,13.61145A47.59979,47.59979,0,0,0,909.7799,397.32929Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <path
        d="M567.66289,711.848c-.06775-.18823-.12933-.37866-.19616-.56726-.13251.1167-.25947.23718-.40314.34619Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <path
        d="M640.15813,683.694a24.742,24.742,0,0,0-9.239-18.05884c-2.00568-1.58618-4.32581-2.92566-5.65369-5.1109a34.96632,34.96632,0,0,1-1.92059-4.78961c-2.64191-6.28833-10.17127-9.63038-16.92523-8.67817s-12.599,5.4953-16.536,11.06519a11.255,11.255,0,0,0-15.73957,13.04584c-3.49756-2.81757-9.15509-.56378-11.33838,3.36114-2.18322,3.92487-1.83673,8.72754-1.18762,13.17169a124.45892,124.45892,0,0,0,5.84864,23.58039c3.40551-2.9989,3.19622-8.46027,2.78-13.07691-.43243-4.7962-.17334-10.652,4.02069-13.01873,4.05207-2.28656,8.97016.22381,13.438,1.52227a25.39644,25.39644,0,0,0,21.00812-3.3045c2.32739-1.57477,4.39935-3.54712,6.90826-4.81275s5.7016-1.70513,8.03748-.143c2.13318,1.42657,3.00061,4.0874,3.72644,6.54883q2.58042,8.75043,5.16077,17.50079A24.74173,24.74173,0,0,0,640.15813,683.694Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
      <path
        d="M368.94671,398.03333a89.42151,89.42151,0,0,0,.38928-9.07709,42.72607,42.72607,0,0,0-3.22284-17.495,26.88311,26.88311,0,0,0-14.06629-13.589,25.66544,25.66544,0,0,0-19.24261.01684c-5.57-3.5191-11.75873-6.30889-18.27631-6.65448-6.51764-.34552-13.40863,2.07245-17.42511,7.39509a28.44043,28.44043,0,0,1-3.04431,3.955c-3.22857,2.98987-8.13513,2.5586-12.42645,1.94727q2.88711,2.47449,5.77576,4.94891-3.49485.91306-6.9903,1.826,4.07868,2.47358,8.15662,4.94727c-7.89331,4.73773-11.481,15.07849-10.53327,24.47009.89655,8.8916,5.18933,17.0224,10.321,24.24927,2.04706-4.31989,1.606-9.489,1.5722-14.35443a32.48709,32.48709,0,0,1,.28809-4.71967c17.34265,4.15723,35.46356,6.82526,53.84033.22266l3.68787-9.02582c.51751.05322,1.03534.10321,1.55261.1582l2.13653,8.876c4.844,1.97992,9.69812,3.71033,14.55414,1.892.23627,1.371.37854,2.77014.48786,4.11493.41674-1.60858.77832-3.22907,1.10437-4.856a14.35868,14.35868,0,0,0,1.83862-1.1745A39.5123,39.5123,0,0,0,368.94671,398.03333Z"
        transform="translate(-211.84 -67.97468)"
        fill="#2f2e41"
      />
    </svg>
  );
};

export default AboutSvg;
